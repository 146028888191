<template>
  <b-row>
    <b-col
      cols="12"
      md="9"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <name-text />
          </b-col>
          <b-col
            cols="12"
          >
            <surname-text />
          </b-col>
          <b-col
            cols="12"
          >
            <phone-text />
          </b-col>
          <b-col
            cols="12"
          >
            <email-text />
          </b-col>
          <b-col cols="12">
            <password />
          </b-col>
        </b-row>
      </b-card>
      <email-info-card v-if="!dataItem.id" />
    </b-col>
    <b-col
      cols="12"
      md="3"
    >
      <customer-card />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import NameText from '@/views/Admin/Customer_accounts/elements/NameText.vue'
import SurnameText from '@/views/Admin/Customer_accounts/elements/SurnameText.vue'
import PhoneText from '@/views/Admin/Customer_accounts/elements/PhoneText.vue'
import EmailText from '@/views/Admin/Customer_accounts/elements/EmailText.vue'
import Password from '@/views/Admin/Customer_accounts/elements/Password.vue'
import CustomerCard from '@/views/Admin/Customer_accounts/elements/CustomerCard.vue'
import EmailInfoCard from '@/views/Admin/Customer_accounts/elements/EmailInfoCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    NameText,
    SurnameText,
    PhoneText,
    EmailText,
    Password,
    CustomerCard,
    EmailInfoCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
  },
}
</script>
