<template>
  <b-card title="Müşteri Bilgisi">
    <b-list-group>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Firma Adı
        </div>
        <div>
          {{ customer.company }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Telefon
        </div>
        <div>
          {{ customer.phone_region_code? '+'+customer.phone_region_code : '' }}
          {{ customer.phone }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Bölge
        </div>
        <div>
          {{ customer.country }},
          {{ customer.city }}
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-button
      class="mt-1"
      variant="primary"
      block
      target="_blank"
      :href="'/app/customers/view/' + customer.id"
    >
      <FeatherIcon icon="EyeIcon" />
      Müşteriyi Görüntüle
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {
  name: 'CustomerCard',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/dataItem']
    },
  },
}
</script>
